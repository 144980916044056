.time-picker {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    &__container {
        display: flex;
        justify-content: center;
        margin: 10px 10px 10px 0px;

        input {
            min-width: 40px;
        }
    }
}