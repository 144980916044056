.job-chains {
    width: 100%;
    flex: 1 1 auto;
    overflow: hidden;
    padding: 10px;
    background-color: rgba($color-grey-light, .3);
    color: $color-text;
    font-family: $font-real-text-pro-semilight;
    font-size: 12px;

    &--overflow-auto {
        overflow: auto;
    }

    > .infinite-scroll-component__outerdiv {
        width: 100%;
        height: calc(100% - 40px);

        > .infinite-scroll-component  {
            overflow-x: hidden !important;
        }
    }

    &__filter-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        &__wrapper {
            display: flex;
            align-items: center;
        }

        &__clear-text {
            margin-left: 5px;
            font-family: $font-real-text-pro-semilight;
            font-size: 14px;
            color: rgba($color-text, .4);
            cursor: pointer;

            &:hover {
                color: $color-text;
            }
        }

        &__search {
            width: 300px;
        }
    }

    .container {
        display: flex;
        flex-direction: column;
        padding: 10px;
        background-color: rgba($color-white, .4);
        border: 1px solid rgba($color-grey-light, .9);

        &:not(:first-of-type) {
            margin-top: 10px;
        }

        &__name {
            display: inline-flex;
            justify-content: center;
            width: 100%;
            padding: 5px 5px 10px 5px;
            font-family: $font-real-text-pro-bold;
            font-size: 14px;
            color: $color-blue;
            text-transform: uppercase;
        }
    }

    &__job-chain {
        background-color: rgba($color-grey-light, .8);
        padding: 10px;
        margin-bottom: 10px;
        transition: all 0.3s ease;

        &__header {
            display: flex;
            justify-content: space-between;
            padding: 10px;

            &__col {
                display: flex;
                align-items: center;
                justify-content: center;

                &__name {
                    font-family: $font-real-text-pro-bold;

                    &:hover {
                        padding: 5px;
                        border-radius: 2px;
                        background-color: rgba($color-grey-text-light, .3);
                        border: 1px solid $color-grey-light;
                    }

                    &.no-hover {
                        &:hover {
                            padding: 0;
                            border-radius: unset;
                            border: none;
                            background-color: unset;
                        }
                    }
                }

                &__wrapper {
                    margin-left: 50px;
                    display: flex;
                    flex-direction: column;

                    &:first-of-type {
                        margin-left: 10px;
                    }
                }

                .form-input {
                    width: 350px;
                    border-bottom: 1px solid $color-grey-B0B0B0;
                    input {
                        font-family: $font-real-text-pro-bold;
                        text-align: center;
                        font-size: 12px;
                    }
                }

                .checkbox-wrapper {
                    cursor: default;
                }

                .calendar {
                    fill: $color-blue;

                    &--red {
                        fill: $color-light-red;
                    }
                }

                .chevron {
                    width: 20px;
                    height: 20px;
                    margin-left: 20px;
                    
                    > span {
                        svg {
                            fill: $color-blue;
                        }
                    }
                }
            }

            .btn {
                font-size: 12px;
                margin-left: 10px;
            }

            .add-chain-icon {
                width: 20px;
                height: 20px;
                fill: $color-blue;
                margin-left: 15px;
            }

            .delete-chain-icon {
                width: 20px;
                height: 20px;
                fill: $color-light-red;
                margin-left: 20px;
            }
        }

        &__wrapper {
            display: inline-flex;
            width: 100%;
            min-height: 220px;
            background-color: rgba($color-white, .4);
            padding: 10px;
            overflow: auto;
        }

        &__content {
            display: flex;
            background-color: rgba($color-white, .4);
            padding: 10px;

            &__next-separator {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 10px;
                width: 10px;

                > .svg-icon {
                    width: 15px;
                    height: 15px;
                    fill: $color-blue;
                }
            }

            &__dots-loader-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
            }

            &__wrapper-inline-flex {
                display: inline-flex;

                &.flex-col {
                    flex-direction: column;
                }
            }
        }
    }
}