.table {
  width: 100%;
  table-layout: fixed;
  font-feature-settings: 'tnum', 'lnum';

  .MuiTableCell-head {
    height: 100%;

    > div {
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      position: relative;
      align-items: center;

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .resize {
        position: absolute;
        top: 0;
        right: -10px;
        padding-left: 3px;
        height: 100%;
        cursor: col-resize;
        text-align: right;

        > div {
          width: 2px;
          height: 100%;
          background-color: rgba($color-grey-808080, 0.15);
        }
      }
    }
  }

  .MuiTableBody-root {
    .MuiTableRow-root {
      &.clickable-row {
        cursor: pointer;
      }

      .bold-text {
        font-family: $font-real-text-pro-bold !important;
      }

      .not-defined-cell {
        color: $color-grey-B0B0B0 !important;
      }

      .cell-with-icon {
        > .svg-icon {
          width: 20px;
          height: 20px !important;
          margin-right: 5px;
        }
      }

      .cell-payment-method-inner {
        display: inline-flex;
        align-items: center;

        > .svg-icon {
          width: 20px;
          height: 20px !important;
          margin-right: 5px;
        }
      }

      .blue-cell {
        background-color: rgba($color-blue, 0.05);
      }

      .actions-cell {
        .download-wrapper {
          display: inline-flex;
          margin-right: 5px;

          > span {
            color: $color-blue;
          }
        }
      }
    }
  }
}
