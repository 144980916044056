.factory-transactions-details {
    width: 80vw;
    height: 96vh;
    padding: 40px;
    min-width: 1200px;

    @include responsive(big-desktop) {
        width: 70vw;
    }

    &.trx-journal {
        width: 60vw;
        min-width: auto;

        @include responsive(big-desktop) {
            width: 40vw;
        }
    }

    .highlight {
        background-color: $highlight-yellow;
        padding: 2px;
        width: fit-content;
    }

    &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: $color-text;
        margin-top: 15px;

        &__state {
            min-width: 15px;
            width: 15px;
            height: 15px;
            border-radius: 20px;

            &--matched {
                background-color: $color-green-match;
            }

            &--unmatched {
                background-color: $color-light-red;
            }
        }

        &__state-text {
            margin-top: 5px;
            color: $color-blue;
        }

        &__date-time {
            margin-top: 2px;
        }

        &__reason {
            font-family: $font-real-text-pro-semilight;
            color: rgba($color-text, 0.8);
            margin-top: 10px;
            font-size: 12px;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        font-size: 14px;

        &__half-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 40%;
            border-bottom: 1px solid $color-blue;
            color: $color-blue;
            padding-bottom: 5px;

            &.full {
                width: 100%;
            }

            &__title {
                color: $color-text;
                display: flex;
                align-items: center;

                >.svg-icon {
                    margin-right: 10px;
                }
            }

            &__date-time {
                font-family: $font-real-text-pro-semilight;
                margin-top: 2px;

                span {
                    &:nth-child(2) {
                        color: $color-text;
                    }
                }
            }
        }
    }

    &__section {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        &__title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 20px 0;
            color: $color-text;

            >.svg-icon {
                fill: $color-blue;
            }
        }

        &__wrapper {
            display: flex;
            justify-content: space-between;

            .vl {
                border-left: 1px solid $color-blue;
              }

            &__half {
                display: flex;
                flex-wrap: wrap;
                width: 40%;
                max-width: 40%;
                color: $color-text;
                font-family: $font-real-text-pro-semilight;

                &.full {
                    width: 100%;
                    max-width: none;
                }

                &__wrapper {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    &__row {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;

                        &:not(:last-child) {
                            margin-bottom: 15px;
                        }

                        &__payment {
                            display: flex;
                            align-items: center;
                            width: 33%;
                            max-width: 33%;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            span {
                                &:nth-child(2) {
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }

                            >.svg-icon {
                                width: 35px;
                                height: 35px;
                                margin-right: 10px;
                            }
                        }

                        &__paymentNbr {
                            display: flex;
                            align-items: center;
                            width: 33%;
                            min-width: 100px;
                            max-width: 33%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        &__trx-type {
                            display: flex;
                            flex-direction: column;
                            // width: 33%;
                            max-width: 33%;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            span {
                                &:nth-child(1) {
                                    color: $color-blue;
                                    margin-right: 0;
                                }

                                &:nth-child(2) {
                                    width: fit-content;
                                }
                            }
                        }

                        &__amount {
                            display: flex;
                            flex-direction: column;
                            width: 100%;

                            &__header {
                                color: $color-blue;
                            }

                            &__content {
                                display: flex;
                                justify-content: space-between;
                                width: 100%;

                                &__wrapper {
                                    display: flex;
                                    flex-direction: column;
                                    width: 33%;
                                    max-width: 33%;
                                    overflow: hidden;
                                    text-overflow: ellipsis;

                                    &:nth-child(3) {
                                        width: auto;
                                        min-width: 125px;
                                    }

                                    span {
                                        &:nth-child(1) {
                                            font-family: $font-real-text-pro-bold;
                                        }

                                        &:nth-child(2) {
                                            font-size: 11px;
                                            color: $color-blue;
                                        }
                                    }
                                }
                            }

                        }

                        &__item {
                            display: flex;
                            flex-direction: column;

                            span {
                                &:nth-child(1) {
                                    color: $color-blue;
                                }

                                &:nth-child(2) {
                                    width: fit-content;
                                }
                            }

                            &__reference {
                                display: flex;
                                flex-direction: column;
                                
                                &:nth-child(1) {
                                    width: 60%;
                                }

                                &:nth-child(2) {
                                    width: 40%;
                                }

                                span {
                                    &:nth-child(1) {
                                        color: $color-blue;
                                    }

                                    &:nth-child(2) {
                                        width: fit-content;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}