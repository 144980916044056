.user-support {
    &__title {
        margin: 10px 0px;
    }

    &__tickets-section {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: min-content;
        justify-items: center;
    }

    @media screen and (max-width: 1080px) {
        &__tickets-section {
            grid-template-columns: 1fr 1fr;
        }
    }

    @media screen and (max-width: 800px) {
        &__tickets-section {
            grid-template-columns: 1fr;
        }
    }
}