.trxes-wrapper {
    display: flex;
    flex-direction: column;
    margin: $padding-medium 0 $padding-medium 0;

    &__trx {
        display: inline-flex;
        flex-direction: column;

        &:last-of-type {
            margin: $padding-medium 0;
        }

        .blue-separator {
            width: 100%;
            border-bottom: 0.3px solid rgba($color: $color-blue, $alpha: .6);
            margin: 5px 0;
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            max-height: 200px;
            overflow: auto;

            &__fields {
                display: inline-flex;
                align-items: center;
                margin: 10px 0;
                font-family: $font-real-text-pro-semilight;
                font-size: 12px;
                color: $color-text;

                &__text {
                    margin: 0 5px;
                    min-width: 50px;

                    &.limited-size {
                        min-width: 100px;
                        max-width: 100px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    > .highlight {
                        width: fit-content;
                        background-color: $highlight-yellow;
                    }

                    > .bold {
                        font-family: $font-real-text-pro-bold;
                    }
                }

                > .svg-icon {
                    min-width: 50px;
                }

                &__flex-end {
                    white-space: nowrap;
                    flex: 1;
                    text-align: end;
                }
            }
        }
    }
}

.trxes-reason-code {
    display: inline-flex;
    align-items: center;
    width: 100%;
    font-family: $font-real-text-pro-semilight;
    font-size: 12px;
    color: rgba($color-text, .8);
    background-color: rgba($color-grey, .1);
    padding: $padding-small;

    &__comment {
        flex: 1;
    }

    &__reason {
        padding-left: $padding-small;
        border-left: 1px solid $color-grey;
    }

    > .svg-icon {
        margin-right: 10px;
        fill: rgba($color-text, .5);
    }
}

.trxes-footer {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: $padding-medium 0 0 0;

    > .page-nbr {
        font-family: $font-real-text-pro-semilight;
        font-size: 16px;
        color: $color-blue;

    }

    > .svg-icon {
        width: 15px;
        height: 15px;
        margin: 0 10px;
        fill: $color-blue;
        cursor: pointer;

        &.disabled {
            cursor: default;
            fill: rgba($color-grey, .3);
        }
    }
}