.md-stepper-horizontal {
	display: table;
	width: 100%;
}
.md-stepper-horizontal .md-step {
	display: table-cell;
	position: relative;
	padding: 25px 0;
    z-index: 1;
}

.md-stepper-horizontal .md-step:first-child .md-step-bar-left,
.md-stepper-horizontal .md-step:last-child .md-step-bar-right {
	display: none;
}

.md-stepper-horizontal .md-step .md-step-circle {
	width: 15px;
	height: 15px;
	margin: 0 auto;
	background-color: $color-grey-BCBCBC;
	border-radius: 50%;
}

.md-stepper-horizontal.blue .md-step.active .md-step-circle {
	background-color: $color-blue;
}

.md-stepper-horizontal .md-step .md-step-bar-left,
.md-stepper-horizontal .md-step .md-step-bar-right {
	position:absolute;
	top:33px;
	height:1px;
	border-top:1px solid #DDDDDD;
}
.md-stepper-horizontal .md-step .md-step-bar-right {
	right:0;
	left:50%;
  z-index: -1;
}
.md-stepper-horizontal .md-step .md-step-bar-left {
	left:0;
	right:50%;
  z-index: -1;
}