.tooltip {
    background-color: $color-grey !important;
    border-radius: 0 !important;
    font-family: $font-real-text-pro-semilight;
    font-size: 14px !important;
    padding: 12px !important;
    white-space: nowrap !important;
    max-width: fit-content !important;
    pointer-events: none !important;
}
