.dashboard-screen {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;

    .center-loader {                
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    &__top {
        width: 100%;
        height: 280px;
        display: inline-flex;
        padding-bottom: $padding-small;

        @include responsive(big-desktop) {
            height: 315px;
            padding-bottom: $padding-medium;
        }

        &__lists {
            width: 410px;
            background-color: $color-modal-bg;

            &--exports {
                background-color: yellow;
                display: inline-flex;
            }

            &__inner {
                height: 100%;
                width: 100%;
                padding: $padding-medium;
                color: $color-text;
                display: flex;
                flex-direction: column;

                @include responsive(big-desktop) {
                    padding: $padding-big $padding-big 25px $padding-big;
                }

                &__title {
                    > div {
                        display: inline-flex;
                        align-items: center;
                        height: 100%;

                        .svg-icon {
                            margin-right: 10px;
                            width: 10px;
                            height: 10px;

                            svg {
                                fill: $color-blue;
                            }
                        }
                    }
                }

                &__list-wrapper {
                    display: inline-flex;
                    flex-direction: column;
                    flex: 1 1 auto;
                    width: 100%;
                    font-size: 13px;
                    padding-top: 10px;

                    &__list-item {
                        width: 100%;
                        display: inline-flex;
                        padding: 2px 0;
                        font-family: $font-real-text-pro-semilight;
                        color: $color-text;

                        &--header {
                            padding-bottom: 2px;
                            color: $color-blue;

                            > div:first-of-type {
                                opacity: 1 !important;
                            }
                        }

                        > div {
                            width: 145px;
                            text-align: center;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        > div:first-of-type {
                            padding: 0;
                            text-align: left;
                            width: 65px;
                            opacity: 0.7;
                        }

                        > div:last-of-type {
                            flex: 1 1 auto;
                            display: inline-flex;
                            justify-content: flex-end;
                            padding: 0;
                            max-width: 40px;
                        }
                    }
                }
            }
        }

        &__matching {
            width: calc(100% - 410px - 410px);
            min-width: 400px;
            background-color: $color-grey-f2f2f2;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &__inner {
                height: 100%;
                width: 100%;
                padding: $padding-medium;
                color: $color-text;
                display: flex;
                flex-direction: column;

                @include responsive(big-desktop) {
                    padding: $padding-big $padding-big 25px $padding-big;
                }

                &__warning {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    font-family: $font-real-text-pro-semilight;
                    font-size: 14px;
                }

                &__title {
                    width: 100%;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    height: 20px;
                    font-family: $font-real-text-pro-bold;

                    .svg-icon {
                        margin-right: 10px;
                        width: 18px;
                        height: 18px;
                    }
                }

                &__chart {
                    width: 100%;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    height: 186px;
                    padding-top: 18px;
                    margin: auto;

                    &__centered-label {
                        font-family: $font-real-text-pro-semilight;
                        font-size: 20px;
                        fill: $color-text;
                    }

                    &__info {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding: 20px 0;

                        &--second {
                            .svg-icon {
                                width: 20px;
                                height: 20px;
                            }

                            .svg-icon:first-of-type {
                                svg {
                                    fill: $color-green-match;
                                }
                            }

                            .svg-icon:last-of-type {
                                svg {
                                    fill: $color-light-red;
                                }
                            }
                        }

                        &__item {
                            display: flex;
                            width: 85px;
                            flex-direction: column;
                            align-items: center;
                            font-family: $font-real-text-pro-semilight;

                            @include responsive(big-desktop) {
                                width: 150px;
                            }
                            
                            > p:nth-of-type(1) {
                                font-size: 12px;
                            }

                            > p:nth-of-type(2) {
                                font-size: 20px;
                                color: $color-text;
                            }
                        }
                    }
                }

                &__bottom {
                    width: 100%;                 
                    font-family: $font-real-text-pro-semilight;
                    
                    &--centered {
                        align-items: center;
                    }

                    &__view-alerts {
                        height: 30px;
                        background-color: $color-light-red;
                        display: inline-flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 15px;
                        color: $color-white;
                        font-size: 14px;
                        opacity: 0.3;

                        .svg-icon {
                            width: 20px;
                            height: 20px;
                            margin-right: 10px;

                            svg {
                                fill: $color-white;
                            }
                        }
                    }

                    &__info {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 12px;
                        font-family: $font-real-text-pro-semilight;
                        color: rgba($color-grey-7B7B7B, 0.8);
                        width: 100%;

                        span:nth-of-type(2) {
                            margin-left: 3px;
                        }

                        div:nth-of-type(1) {
                            margin-right: 20px;
                        }

                        &__blue {
                            color: $color-blue;
                            margin-left: 2px;
                            margin-right: 3px;
                        }
                    }
                }

                &__no-data-container {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;

                    &__no-totals {
                        width: 100%;

                        > div {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 100%;
                            opacity: 0.1;

                            > svg {
                                width: 65%;
                                height: 65%;
                            }
                        }
                    }
                }
            }
        }
    }

    &__bottom {
        display: flex;
        width: 100%;
        min-height: 380px;
        height: calc(100% - 280px);
        overflow: hidden;
        font-size: 14px;
        color: $color-text;

        @include responsive(big-desktop) {
            height: calc(100% - 315px);
        }

        &__col {
            flex: 1;
            background-color: $color-modal-bg;

            &:nth-child(1) {
                display: flex;
                flex-direction: column;
                max-width: calc(410px - #{$padding-small});

                @include responsive(big-desktop) {
                    max-width: calc(410px - #{$padding-medium});
                }
            }

            &:nth-child(2) {
                width: calc(100% - 410px - 410px);
                min-width: 400px;
                margin-right: $padding-small;
                margin-left: $padding-small;
                background-color: $color-white;

                @include responsive(big-desktop) {
                    margin-right: $padding-medium;
                    margin-left: $padding-medium;
                }
            }

            &:nth-child(3) {
                max-width: calc(410px - #{$padding-small});
                background-color: $color-white;

                @include responsive(big-desktop) {
                    max-width: calc(410px - #{$padding-medium});
                }
            }

            &__payments {
                display: flex;
                flex-direction: column;
                height: 50%;
                overflow: hidden;
                font-family: $font-real-text-pro-semilight;

                &__content {
                    margin: 10px $padding-medium 10px $padding-medium;
                    overflow: auto;

                    @include responsive(big-desktop) {
                        margin: 20px $padding-big 10px $padding-big;
                    }

                    &__no-data-container {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        opacity: 0.1;

                        svg {
                            width: 90%;
                            height: 90%;
                        }
                    }
                }
            }

            &__banks {
                display: flex;
                flex-direction: column;
                height: 50%;
                overflow: auto;

                &__content {
                    margin: 10px $padding-medium 10px $padding-medium;
                    overflow: auto;
                    font-family: $font-real-text-pro-semilight;
                    flex: 1 1 auto;

                    @include responsive(big-desktop) {
                        margin: 20px $padding-big 20px $padding-big;
                    }

                    &__no-data-container {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        opacity: 0.1;

                        svg {
                            width: 90%;
                            height: 90%;
                        }
                    }

                    &__row {
                        display: flex;
                        align-items: center;
                        width: 100%;                        
                        padding: 5px 10px 5px 0;
                        font-size: 12px;

                        &__totals {
                            display: inline-flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;                                                   
                            padding-left: 32px;
                            color: $color-text;
                        }
                    } 
                }
            }

            &__row {
                height: 50%;

                &:nth-child(2) {
                    padding-top: $padding-small;

                    @include responsive(big-desktop) {
                        padding-top: $padding-medium;
                    }
                }

                &__trans-volume {
                    height: 100%;
                    background-color: $color-modal-bg;

                    &__container {
                        width: 100%;
                        height: calc(100% - 36px);
                        padding: 0 $padding-medium $padding-medium $padding-medium;

                        @include responsive(big-desktop) {
                            padding: 0 $padding-big $padding-big $padding-big;
                        }

                        &.no-data {
                            display: flex;
                            justify-content: center;
                            align-items: center;  
                            font-family: $font-real-text-pro-semilight;
                            font-size: 14px;
                        }
                    }
                }

                &__scheduler-and-users {
                    display: flex;
                    height: 100%;
                    background-color: $color-white;
                }

                &__scheduler {
                    display: flex;
                    flex-direction: column;
                    width: 60%;
                    margin-right: 16px;
                    background-color: $color-modal-bg;

                    &__content {
                        height: calc(100% - #{$padding-medium} - #{$padding-medium});
                        margin: $padding-medium;
                        overflow: auto;
                        font-family: $font-real-text-pro-semilight;

                        @include responsive(big-desktop) {
                            height: calc(100% - (20px + #{$padding-big}) - 20px - 20px);
                            margin: 20px $padding-big 20px $padding-big;
                        }

                        > table {
                            table-layout: inherit !important;
                            > tbody {
                                > tr {
                                    background-color: unset !important;
            
                                    td {
                                        padding: 8px 11px 8px 0 !important;
                                        font-size: 12px !important;
                                        max-width: 100% !important;
            
                                        &:nth-child(1),
                                        &:nth-child(2),
                                        &:nth-child(3),
                                        &:nth-child(4) {
                                            width: 100%;
                                        }
            
                                        &:nth-child(2) {
                                            font-family: $font-real-text-pro-bold !important;
                                        }
            
                                        &:nth-child(4) {
                                            text-align: right;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &__users {
                    display: flex;
                    flex-direction: column;
                    width: 40%;
                    overflow: auto;
                    background-color: $color-modal-bg;

                    &__row {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        font-family: $font-real-text-pro-semilight;
                        font-size: 14px;

                        &__name-wrapper {
                            display: flex;
                            align-items: center;


                            &__name {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;                                
                                width: 100%;
                                color: $color-text;

                                span {
                                    &:nth-child(1) {
                                        min-width: 100px;                                            
                                        overflow: hidden;
                                        font-size: 12px;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;                                        
                                    }

                                    &:nth-child(2) {
                                        overflow: hidden;
                                        font-family: $font-real-text-pro-semilight;
                                        font-size: 12px;
                                        text-overflow: ellipsis;
                                        white-space: inherit;
                                        color: $color-blue;
                                    }
                                }
                            }
                        }


                        &__title {
                            color: $color-blue;
                        }

                        &__date {
                            font-size: 12px;
                            color: $color-text;
                            opacity: 0.7;                            
                        }

                        &:not(:last-child) {
                            margin-bottom: 10px;
                        }
                    }
                }

                &__latest-activities {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    overflow: auto;
                    font-size: 12px;
                    background-color: $color-grey-5A5A5A;

                    &__row {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        font-family: $font-real-text-pro-semilight;

                        &__title {
                            color: $color-blue;
                        }

                        &__msg {
                            color: $color-white;
                        }

                        &:not(:last-child) {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }

        &__column-1 {
            display: flex;
            flex-direction: column;
            width: calc(430px - 16px);
            min-height: 490px;
            background-color: $color-modal-bg;

            &__payments {
                display: flex;
                flex-direction: column;
                height: 45%;
                overflow: hidden;
            }

            &__banks {
                display: flex;
                flex-direction: column;
                height: calc(50% - #{$padding-medium});
                overflow: auto;

                &__content {
                    padding: 20px $padding-big 0 $padding-big;
                    overflow: auto;
                    font-family: $font-real-text-pro-semilight;

                    &__row {
                        display: flex;
                        width: 100%;
                        align-items: center;
                        padding: 5px 10px 5px 0;
                        font-size: 12px;

                        &__totals {
                            width: 100%;
                            display: inline-flex;
                            align-items: center;
                            justify-content: space-between;
                            padding-left: 32px;
                            color: $color-blue;

                            span {
                                &:nth-child(2) {
                                    color: $color-text;
                                }
                            }
                        }
                    } 
                }
            }
        }

        &__header {
            display: flex;
            align-items: center;
            width: 100%;
            padding: $padding-medium $padding-medium 0 $padding-medium;
            font-family: $font-real-text-pro-bold;

            @include responsive(big-desktop) {
                padding: $padding-big $padding-big 0 $padding-big;
            }

            .subtitle {
                display: flex;
                align-items: center;

                &.regular {
                    font-family: $font-real-text-pro-semilight;
                }

                > .square {
                    width: 10px;
                    height: 10px;
                    margin-right: 5px;
                    margin-left: 15px;

                    &.blue {
                        background-color: $color-blue;
                    }

                    &.grey {
                        background-color: $color-grey;
                    }
                }
            }


        }

        &__content {
            display: flex;
            flex: 1 auto;
            flex-direction: column;
            padding: 10px $padding-medium $padding-medium $padding-medium;
            overflow: hidden;

            @include responsive(big-desktop) {
                padding: 10px $padding-big $padding-big $padding-big;
            }

            &.no-data {
                justify-content: center;
                align-items: center;  
                font-family: $font-real-text-pro-semilight;
                
                &.white {
                    color: $color-white;
                }
            }

            &.payments {
                height: 100%;
                overflow: auto;
            }

            &--bigger-padding {
                padding: 10px $padding-medium $padding-medium $padding-medium;

                @include responsive(big-desktop) {
                    padding: 10px $padding-big $padding-big $padding-big;
                }
            }

            &__missing {
                width: 100%;
                height: 100%;
                display: grid;
                grid-gap: 10px;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-template-rows: 1fr 1fr min-content;
                justify-items: center;
                font-size: 16px;

                @include responsive(big-desktop) {
                    padding-top: 10px;
                }

                div {
                    text-align: center;

                    p:nth-of-type(2) {
                        font-size: 12px;
                        font-family: $font-real-text-pro-semilight;
                    }
                }

                div:nth-of-type(6) {
                    grid-column: 2 / 4;
                }

                &__empty {
                    grid-column: 1 / -1;
                    grid-row: 1 / -1;
                    width: 100%;
                    height: 100%;
                    color: $color-grey-BCBCBC;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    .svg-icon {
                        width: 60px;
                        height: 60px;
                    }

                    p {
                        font-size: 16px;
                        margin-top: 10px;
                        font-family: $font-real-text-pro-semilight;
                    }
                }

                &__button {
                    grid-row: 3 / 4;
                    grid-column: 1 / -1;
                    width: 100%;
                }
            }

            > .infinite-scroll-component__outerdiv {
                width: 100%;
                overflow: auto;
            }
        }
    }
}
