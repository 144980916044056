.mm-container {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 80vw;
    max-width: 97vw;
    min-height: 95vh;
    max-height: 95vh;
    // overflow-x: auto;
    font-size: 14px;
    background: $color-modal-bg;
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.15);
    transform: translateX(-50%) translateY(calc(-50% - 0.5px)); // hack to fix blurry text

    &__sidebar {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 10%;
        min-width: 150px;
        padding: $padding-big 0;
        color: $color-white;
        background-color: $error-bg-color;

        &__types {
            width: 100%;
            font-family: $font-real-text-pro-semilight;

            div {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                margin: 15px 0;

                :nth-child(1) {
                    font-size: 16px;
                }

                &.selected {
                    background-color: rgba($color-white, 0.35);
                }
            }
        }

        &__progress {
            position: relative;

            div:nth-of-type(1) {
                &.MuiCircularProgress-colorPrimary {
                    color: rgba($color-white, 0.35);
                }
            }

            div:nth-of-type(2) {
                &.MuiCircularProgress-colorPrimary {
                    color: $color-white;
                }
            }

            &__bg {
                position: absolute;
                top: 0;
                left: 0;
            }

            &__label {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-family: $font-real-text-pro-semilight;
                text-align: center;
                font-size: 16px;

                p:last-of-type {
                    font-size: 10px;
                }
            }
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        width: 90%;
        min-width: 900px;
        padding: $padding-big $padding-medium;
        overflow: auto;
        background: $color-modal-bg;

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: max-content;
            margin-bottom: 20px;
            color: $error-bg-color;

            &__btns {
                display: flex;
                justify-content: space-between;
                align-items: center;

                button {
                    margin: 0 10px;
                    padding: 0.4em 1rem;
                    font-size: 14px;
                    background-color: $color-grey;

                    &.btn--disabled {
                        background-color: rgba($color-grey, 0.4);
                    }
                }

                .svg-icon {
                    width: 20px;
                    height: 20px;
                    margin-left: 10px;

                    svg {
                        fill: $error-bg-color;
                    }
                }
            }
        }

        &__description {
            display: flex;
            flex-direction: column;
            // flex: 1;

            &:nth-child(3) {
                height: 100%;
            }

            &__title {
                padding: $padding-small $padding-medium;
                color: $color-white;
                border-radius: 4px;

                &--red {
                    background-color: $error-bg-color;
                }

                &--blue {
                    background-color: $color-blue;
                }
            }

            &__content {
                display: flex;
                flex: 1 1 auto;
                justify-content: center;
                padding: 20px;
                background-color: $color-white;

                &.centered {
                    align-items: center;
                }

                &__wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;

                    &__section-fix {
                        display: flex;

                        &__fields-wrapper {
                            display: flex;
                            flex-direction: column;

        
                            &:not(:first-child) {
                                margin-left: 40px;
                            }
        
                            &__input {
                                width: 200px;
                                &:not(:first-child) {
                                    margin-top: 20px;
                                }
        
                                > .form-input {
                                    height: 40px;
                                    border-bottom: 1px solid $color-select-border;
                                }

                                .detail {
                                    font-family: $font-real-text-pro-semilight;
                                    color: $color-text;
                                    height: 40px;
                                    border: none;
                                    width: 100%;
                                    font-size: 16px;
                                    align-items: center;
                                    max-width: 100%;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
        
                                > span {
                                    font-family: $font-real-text-pro-semilight;
                                    color: $color-blue;
                                }
                                
                                @include responsive(big-desktop) {
                                    width: 250px;
                                }
                            }
                        }
                    }

                    &__save-btn {
                        text-align: center;
                        > button {
                            margin: 25px 10px 20px 10px;
                            padding: 0.4em 1rem;
                            font-size: 14px;
                            text-align: center;
                        }
    
                        > .error-msg {
                            height: 20px;
                            font-family: $font-real-text-pro-semilight;
                            color: $error-bg-color;
                            font-size: 14px;
                        }
                    }
                }

                &__title {
                    color: $error-bg-color;
                }

                &__text {
                    margin-bottom: 10px;
                    font-family: $font-real-text-pro-semilight;
                    color: $color-text;

                    &.desc {
                        // max-height: 200px;
                        margin-top: 10px;
                        margin-bottom: 0px;
                        padding: 10px 10px 0 0;
                        overflow: auto;
                    }
                }

                &__text-icon-wrapper {
                    display: flex;
                    flex: 1 1 auto;
                    justify-content: space-between;

                    &__header {
                        width: 80%;

                        &.contents {
                            width: 96%;
                            padding-bottom: 10px;
                        }
                    }

                    &__icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 15%;
                        margin-right: 10px;
                        margin-bottom: 20px;
                        background-color: $error-bg-color;
                        border-radius: 5px;

                        &.contents {
                            width: 4%;
                            margin-right: 30px;
                            margin-bottom: 0px;
                        }

                        .svg-icon {
                            width: 20px;
                            height: 20px;

                            svg {
                                fill: $color-white;
                            }
                        }
                    }
                }

                &__left {
                    width: 20%;
                    border-right: 1px solid $color-grey-light;
                }

                &__right {
                    width: 80%;
                    padding-left: 20px;
                }
            }
        }

        &__allfixed {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;

            > .svg-icon {
                width: 125px;
                height: 125px;
            }

            > p {
                color: $color-text;
                font-size: 16px;
            }
        }
    }
}
