.image-container {
    &__avatar {
        position: relative;
        width: 60px;
        height: 60px;
        background-color: $color-grey-B0B0B0;
        border-radius: 50%;


        img {
            border-radius: 50%;
        }

        &__camera-wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 60px;
            height: 60px;
            transform: translate(-50%, -50%);


            &__initials {
                position: absolute;
                top: 50%;
                left: 50%;
                font-family: $font-real-text-pro-semilight;
                font-size: 22px;
                color: $color-white;
                transform: translate(-50%, -50%);
            }


            &__icon {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 22px;
                height: 22px;
                opacity: 0;
                transform: translate(-50%, -50%);

                &.disabled {
                    opacity: 1;
                }

                .svg-icon {
                    width: 22px;
                    height: 22px;


                    svg {
                        fill: $color-white;
                    }
                }
            }


            &:hover > &__icon {
                opacity: 1;
            }


            &:hover > &__initials {
                opacity: 0;
            }
        }
    
    }
}
