.MuiPopover-paper {
    box-shadow: none !important;
}

.popover-content {
    background-color: $color-grey;
    padding: $padding-medium;
    max-width: 400px;
    min-width: 200px;
    box-shadow: none !important;

    &--md {
        max-width: 600px;
        min-width: 300px;
    }

    &--light-theme {
        background-color: $color-white;
        box-shadow: none !important;
    }
    
    .popover-fields-wrapper {
        display: flex;
        flex-direction: column;
        margin: 0 0 $padding-medium 0;
        font-family: $font-real-text-pro-semilight;
        font-size: 12px;
        color: $color-white;

        > .section-title {
            &.light-grey {
                color: $color-grey-text-light;
            }
        }

        .blue-separator {
            width: 100%;
            border-bottom: 0.3px solid rgba($color: $color-blue, $alpha: .6);
            margin: 5px 0;
        }

        .popover-fields {
            display: inline-flex;
            color: $color-text;
            align-items: center;

            > .text {
                margin: 0 5px;
                min-width: 50px;

                > .highlight {
                    background-color: $highlight-yellow;
                    width: fit-content;
                }
            }

            > .bold {
                flex: 1;
                text-align: end;
                font-family: $font-real-text-pro-bold;
                white-space: nowrap;
            }
        }

        .popover-chip {
            display: inline-flex;
            align-items: center;
            margin-right: 10px;
            margin-top: 10px;
            padding: 2px 10px;
            font-family: $font-real-text-pro-semilight;
            font-size: 12px;
            color: $color-white;
            background-color: $color-blue;
            border: 1px solid $color-blue;
            border-radius: 25px;
            width: fit-content;

            > .svg-icon {
                margin-right: 5px;
                width: 20px;
                height: 20px;
            }
        }
    }
}