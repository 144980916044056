.help-center-screen {
    width: 100%;
    height: 100%;
    background-color: $color-grey-f8f8f8;
    display: inline-flex;

    &__content {
        padding: $padding-big;
        width: 100%;
        height: 100%;
        overflow-y: auto;

        &__floating-container {
            position: absolute;
            bottom: $padding-medium;
            right: $padding-big;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            .svg-icon {
                width: 58px;
                height: 58px;
            }
        }
    }
}