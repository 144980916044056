// TABLES 
.MuiTable-root {
    &.MuiTable-stickyHeader {
        .MuiTableHead-root {
            .MuiTableRow-root {
                .MuiTableCell-root {
                    padding-left: 10px;
                    padding-right: 10px;
                    padding-bottom: 10px;
                    font-family: $font-real-text-pro-semilight;
                    font-size: 14px;

                    &.MuiTableCell-head {
                        color: $color-blue;
                        // background-color: $color-modal-bg;
                        border-bottom: none;
                    }

                    &.MuiTableCell-stickyHeader {
                        background-color: $color-grey-f4f4f4;
                    }
                }
    
            }
        }
    }

    .MuiTableBody-root {
        .MuiTableRow-root {
            &:nth-child(even) {
                background-color: $color-grey-EFEFEF;
            }
            .MuiTableCell-root {
                padding: 10px 10px;
                font-family: $font-real-text-pro-semilight;
                font-size: 14px;

                &.MuiTableCell-body {
                    color: $color-text;
                    border-bottom: none;
                    //padding-right: 20px;

                    .svg-icon {
                        height: 17px;
                        cursor: pointer;
                        &.table-icon-active {
                            & > span > svg {
                                fill: rgba($color-blue, 1);
                            }
                        }

                        &.table-icon-delete {
                            & > span > svg {
                                fill: rgba($color-light-red, 1);
                            }
                        }

                        & > span > svg {
                            fill: rgba($color-grey-5A5A5A, .2);
                        }
                    }

                    &.right {
                        align-items: flex-end;
                    }
                }
            }
        }
    }
}

// TABS
.Mui-table-wrapper {
    width: 100%;
    margin-top: 10px;

    > .selected {
        height: 100%;
        width: 100%;
    }

    .MuiAppBar-root {
        &.MuiAppBar-colorPrimary {
            color: $color-text;
            background-color: transparent;
            box-shadow: none;

            .MuiTabs-root {
                min-height: 30px;

                .svg-icon {
                    width: 16px;
                    height: 16px;
                    margin-top: 5px;
                    fill: $color-blue;
                    right: 0;
                    position: absolute;

                    &.add-no-access {
                        fill: rgba($color-blue, .5);
                    }
                }
    
                .MuiTabs-indicator {
                    width: 130px;
                    background-color: $color-blue;
                }
    
                .MuiTab-root {
                    &.MuiTab-textColorInherit {
                        min-width: 70px;
                        min-height: 30px;
                        margin-right: 30px;
                        padding: 0;
                        font-family: $font-real-text-pro-semilight;
                        font-size: 14px;
                        text-transform: none;
                        color: $color-grey;
                        opacity: .4;  

                        @include responsive(big-desktop) {
                            margin-right: 70px;
                        }
    
                        &.Mui-selected {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

td {
    max-width: 133px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    > .svg-icon {
        > span {
            display: inline-flex;
        }
    }

    &.contracts-payment-method {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: none !important;

        > .svg-icon {
            &.payment-icon {
                height: 20px !important;
                margin-right: 10px;
                pointer-events: none;
           }
        }

    }

    @media screen and (max-width: 1500px) {
        max-width: 90px !important;
    }
}

// MENU

.MuiPaper-root {
    &.MuiPaper-rounded {
        border-radius: 0;
    }
    .MuiMenu-list {
        background-color: $color-grey;
        min-width: 126px;
        color: $color-white;

        .MuiMenuItem-root {
            font-family: $font-real-text-pro-semilight;
            font-size: 16px;

            &:hover {
                background-color: rgba($color-white, 0.2);
            }
        }
    }
}

// Radio buttons
.MuiButtonBase-root {
    &:hover{
        background-color: transparent;
    }
}
.MuiRadio-colorSecondary {
    color: $color-blue !important; 
}

// Tooltip
.MuiTooltip-popper, .MuiTooltip-tooltip {
    background-color: $color-grey !important;
    font-family: $font-real-text-pro-semilight;
    font-size: 14px !important;
    padding: 2px !important;
    // This padding gives problems on firefox browsers
    @media(min--moz-device-pixel-ratio:0) {
        padding: 2px 8px 2px 2px !important;
    }
    margin: 8px !important;
    white-space: nowrap !important;
    max-width: fit-content !important;
    pointer-events: none !important;
    z-index: 10000 !important;
}