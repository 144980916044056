// sass-lint:disable class-name-format
.toast-container {
    top: 33px !important;
    right: 5px !important;

    .Toastify {
        background-color: yellow;
        &__toast {
            margin-bottom: 5px;

            &--success {
                background-color: $color-blue;

            }

            &--error {
                background-color: $color-light-red;
            }

            &--warning {
                background-color: $color-toast-warning;
            }

            // .Toastify__toast-container--top-right

            &-body {
                font-family: $font-real-text-pro-semilight;
                font-size: 13px;
                word-break: keep-all;
                padding: 0 19px;

                .with-link {
                    a {
                        color: rgba( #FFFFFF, 0.85);
                        font-size: 12px;
                    }

                    p {
                        margin-bottom: 5px;
                    }
                }

                h5 {
                    text-transform: uppercase;
                    font-size: 15px;
                    font-family: $font-real-text-pro-bold;
                }

                p {
                    display: flex;
                    align-items: center;
                    //line-height: 10px;

                    .svg-icon {
                        width: 15px;
                        height: 15px;
                        margin: 0 10px 0 10px;

                        svg {
                            width: 15px;
                            height: 15px;

                            path {
                                fill: #ffffff;
                            }
                        }
                    }
                }
            }
        }
    }
}
// sass-lint:enable class-name-format
