.help-center-sidebar {
    height: 100%;
    width: $help-sidebar-width;
    background-color: $color-white;
    padding: 5px 0 5px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__top {
        &__header {
            padding: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: $color-blue;

            &__title {
                font-size: 28px;
                line-height: 28px;
                margin-top: 10px;
                margin-bottom: 5px;
                font-family: $font-real-text-pro-bold;
            }

            &__subtitle {
                font-size: 16px;
                line-height: 20px;
                font-family: $font-real-text-pro-semilight;
            }
        }

        &__search {
            padding: $padding-small $padding-big $padding-small $padding-big;
            margin-bottom: 5px;
        }

        &__filters {

            display: flex;
            flex-direction: column;
            padding: 0 $padding-medium;
            color: $color-grey-5A5A5A;
            max-height: 720px;
            overflow-y: auto;

            &__title {
                padding-bottom: $padding-medium $padding-big $padding-small $padding-big;
                font-family: $font-real-text-pro-bold;
                font-size: 16px;
            }

            &__item {
                font-family: $font-real-text-pro-semilight;
                font-size: 14px;
                padding-bottom: 15px;

                >p:first-child {
                    color: $color-blue;
                    padding-bottom: 3px;
                }

                &__user-select {
                    padding: 5px 0;
                }

                .app-screen_factory__row__calendar__slider {
                    margin: 0;

                    @include responsive(big-desktop) {
                        margin: 0;
                    }
                }
                .calendar .MuiPickersCalendar-transitionContainer {
                    margin-top: 4px;
                    min-height: 205px;
                }

                .filter-chip {
                    @include chip('event-center');

                    .svg-icon {
                        height: 20px;
                        width: 20px;
                        margin-right: 5px;

                        svg {
                            fill: $color-white;
                        }
                    }

                    .origin-icon {
                        margin-right: 2px;
                        margin-left: -3px;
                    }

                    &:not(:last-of-type) {
                        margin-right: 5px;
                    }

                    &.active {
                        color: $color-white;
                        background-color: $color-grey-7D7D7D;

                        .chip-success {
                            svg {
                                fill: $color-green;
                            }
                        }

                        .chip-error {
                            svg {
                                fill: $color-light-red;
                            }
                        }

                        .chip-info {
                            svg {
                                fill: $color-blue;
                            }
                        }

                        .chip-warning {
                            svg {
                                fill: $color-orange-match;
                            }
                        }

                    }

                    &.inactive {
                        color: $color-white;
                        background-color: $color-grey-D9D9D9;

                        svg {
                            fill: $color-grey-c6c6c6;
                        }
                    }
                }
            }

            &__item:last-child {
                padding-bottom: 0;
            }
        }

        &__item {
            padding: $padding-medium $padding-big;
            width: 100%;
            display: inline-flex;
            align-items: center;
            color: $color-text;
            font-size: 14px;
            font-family: $font-real-text-pro-semilight;
            cursor: pointer;

            &.disabled {
                cursor: default;
            }

            &.selected {
                background-color: $color-blue;
                color: $color-white;

                .svg-icon {
                    svg {
                        fill: $color-white;
                        opacity: 0.5;
                    }
                }
            }

            .svg-icon {
                width: 25px;
                height: 25px;
                margin-right: 15px;

                svg {
                    fill: $color-text;
                    opacity: 0.207;
                }
            }

            &__soon {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 28px;
                padding: 0 8px;
                background-color: $color-grey-D7D7D7;
                border-radius: 14px;
                margin-left: 12px;
                color: $color-white;
            }
        }
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        .btn {
            font-size: 14px;
        }

        &__clear {
            margin: 0 auto;
            padding-bottom: 5px;

            font-family: $font-real-text-pro-semilight;
            font-size: 14px;

            span {
                text-decoration: underline;
                color: $color-blue;
                cursor: pointer;
            }
        }
    }
}