.ticket-card {
    width: 100%;
    height: fit-content;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: $padding-small;
    margin-bottom: 10px;
    background-color: $color-white;
    border: none;
    border-radius: 3px;

    &--status-badge {
        display: flex;
        align-items: center;
        padding: 8px;
        justify-content: center;
        background-color: #4DD0E1;
        color: #fff;
        font-family: $font-real-text-pro-semilight;
        word-break: break-word;
    }

    &__header {
        display: inline-flex;
        width: 100%;
        margin: 5px 0px;
        align-items: center;
        justify-content: space-between;

        .chevron {
            height: 15px;
            transition: transform 0.3s ease-out;

            &.clicked {
                transform: rotate(90deg);
            }

            svg {
                fill: $color-blue;
            }
        }
    }

    &__subtitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
    }

    &--key {
        font-size: 14px;
        font-family: $font-real-text-pro-semilight;
        text-transform: uppercase;
        color: $color-blue;
    }

    &--chip {
        border-radius: 14px;
        font-family: $font-real-text-pro-semilight;
        height: 24px;
        display: inline-flex;
        align-items: center;
        padding: 0 10px;
        white-space: nowrap;
        background-color: transparent;
        color: $color-grey-5A5A5A;
        border: 1px solid $color-grey-5A5A5A;
        font-size: 14px;
    }

    &__content {
        font-family: $font-real-text-pro-semilight;
        font-size: 14px;
        margin: 5px 0px;
        word-wrap: break-word;
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: hidden;
        margin: 5px 0px;

        &__item {
            display: flex;
            justify-content: space-between;

            span {
                font-size: 14px;
                color: #808080;
            }

            &--title {
                font-family: $font-real-text-pro-bold;
            }

            &--text {
                font-family: $font-real-text-pro-semilight;
            }
        }

        &__small{
            display: inline-flex;
            justify-content: space-between;

            &__item {
                display: flex;
    
                span {
                    font-size: 12px;
                }
    
                &--title {
                    font-family: $font-real-text-pro-bold;
                    margin-right: 5px;
                    color: $color-blue;
                }
    
                &--text {
                    font-family: $font-real-text-pro-semilight;
                    color: $color-grey-5A5A5A;
                }
            }
        }
    }
}