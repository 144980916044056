.user-manual {
    width: 100%;
    padding-bottom: $padding-big;

    .big-title {
        font-family: $font-real-text-pro-bold;
        font-size: 20px;
        color: $color-blue;
        margin-bottom: 16px;
    }

    &__header {
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
        padding-top: $padding-big;
        padding-bottom: 45px;
        border-bottom: 2px solid $color-blue;

        .svg-icon {
            width: 164px;
            height: 33px;

            svg {
                fill: $color-blue;
            }
        }

        > p {
            font-family: $font-real-text-pro-semilight;
            font-size: 16px;
            line-height: 22px;
            max-width: 180px;
            text-align: right;
            color: $color-blue;
        }
    }

    &__index {
        padding-top: $padding-big;
        padding-bottom: calc(#{$padding-big} + #{$padding-big});
        padding-left: $padding-big;
        padding-right: $padding-big;

        &__medium-title {
            font-family: $font-real-text-pro-semilight;
            font-size: 16px;
            color: $color-blue;
            margin-top: 15px;
            margin-bottom: 5px;
            cursor: pointer;
        }

        &__small-title {
            font-family: $font-real-text-pro-semilight;
            font-size: 14px;
            margin-bottom: 5px;
            padding-left: $padding-medium;
            color: $color-text;
            cursor: pointer;
        }
    }

    &__section {
        font-family: $font-real-text-pro-semilight;
        padding-left: $padding-medium;
        padding-right: $padding-medium;
        color: $color-text;
        font-size: 14px;

        &:not(:first-of-type) {
            padding-top: 50px;
        }

        img {
            padding-left: $padding-medium;
            padding-right: $padding-medium;
            max-width: 100%;
        }

        ul, ol {
            margin-block-start: 0;
            margin-block-end: $padding-medium;
        }

        .user-manual-table {
            width: 60%;
            color: $color-grey;
            margin: $padding-medium 0;

            &.large {
                width: 90%;
            }

            &.small {
                width: 30%;
            }

            th, td {
                white-space: normal;
                padding: 5px 10px;
            }

            td {
                > img {
                    max-width: 75px;
                }
            }

            th {
                color: $color-blue;
                font-weight: normal;
                text-align: left;

                &.small-cell {
                    max-width: 200px;
                }
            }
        }

        .user-manual-section-text {
            font-family: $font-real-text-pro-semilight;
            font-size: 14px;
            margin-top: 10px;
            margin-bottom: 10px;
            padding-left: $padding-medium;
            padding-right: $padding-medium;
        }

        .user-manual-section-subtitle {
            font-size: 16px;
            font-family: $font-real-text-pro-bold;
            margin-top: 14px;
            padding-left: $padding-medium;
            padding-right: $padding-medium;
            width: 100%;

            &.with-icon {
                display: inline-flex;
                align-items: center;

                img {
                    padding: 0;
                    margin-right: 5px;
                    width: 20px;
                }
            }
        }

        .medium-title {
            font-family: $font-real-text-pro-bold;
            font-size: 18px;
            color: $color-blue;
            margin-top: 10px;
        }

        .small-title {
            font-family: $font-real-text-pro-semilight;
            font-size: 16px;
            color: $color-blue;
            padding-top: $padding-medium;
        }

        .normal-img {
            margin-top: 5px;
        }

        .img-wrapper {
            width: 100%;
            display: inline-flex;
            margin-top: 10px;
            margin-bottom: 10px;

            img {
                max-width: 75%;
            }

            .user-manual-section-text {
                margin-top: 0;
                margin-bottom: 0;
            }

            &.text-left {
                img {
                    padding-left: 0;
                }

                .user-manual-section-text {
                    padding-right: 15px;
                }
            }

            &.text-right {
                img {
                    padding-right: 0;
                }

                .user-manual-section-text {
                    padding-left: 15px;
                }
            }
        }
    }
}