.factory-upload {
    width: 100%;
    flex: 1 1 auto;
    overflow: hidden;
    font-size: 14px;
    background-color: $color-grey-f4f4f4;
    padding: $padding-medium;

    &__container {
        display: flex;
        flex-direction: column;
        width: 15%;

        p {
            margin-bottom: 10px;
            font-family: $font-real-text-pro-semilight;
            color: $color-blue;
        }

        &:not(:nth-of-type(1)) {
            margin-top: 20px;
        }

        &__error-msg{
            font-family: $font-real-text-pro-bold;
            color: $color-light-red;
        }
    }

    &__dropzone-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: baseline;
        width: 35%;
        margin-top: 20px;
        border-radius: 4px;
        max-height: calc(100% - 290px);
        overflow: hidden;

        &__dropzone {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba($color-grey-light, .4);
            font-family: $font-real-text-pro-semilight;
            color: rgba($color-text, .5);
            width: 100%;
            min-height: 150px;
            border: 2px dashed $color-select-border;
            cursor: pointer;
            border-radius: 4px;

            > p {
                text-align: center;
                padding: 10px 15px;
            }

            &.drag-accept {
                border: 1px dashed $color-green-match;
            }

            &.drag-reject {
                border: 1px dashed $color-light-red
            }

            &.disabled {
                cursor: auto;
            }

            &.enabled {
                &:hover {
                    color: $color-text;
                }
            }
        }

        &__files-list {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            overflow: auto;
            margin-top: 10px;
            padding: 10px 10px 10px 0;

            p {
                font-family: $font-real-text-pro-semilight;
                color: $color-blue;
            }

            ul {
                padding: 0;
                margin: 0;

                li {
                    width: 100%;
                    display: inline-flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 5px 0;
                    font-family: $font-real-text-pro-semilight;
                    color: $color-text;
                    list-style: none;

                    > .svg-icon {
                        width: 16px;
                        height: 16px;
                        fill: $color-light-red;
                    }
                }
            }

            &__submit-btn {
                width: 100%;
                margin-top: 10px;
            }
        }
    }

    .unsaved-changes {
        font-size: 14px;
        margin-top: 10px;
    }

    .warning-msg-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35%;
        height: 100px;
        padding: 20px 15px;
        background-color: $color-blue;
        color: $color-white;
        margin: 10px 0 0 0;
        font-family: $font-real-text-pro-semilight;
        word-break: break-word;
    }
}