.MuiSwitch-root {
    .MuiSwitch-colorSecondary {
        &.Mui-checked {
            color: $color-blue;
            &:hover {
                background-color: transparent;
            }
        }
    }

    .MuiSwitch-switchBase {
        &:hover {
            background-color: transparent;
        }
    }

    .MuiSwitch-track {
        background-color: $color-blue !important;
    }
}
