.language-dropdown-wrapper {
    padding: 5px 5px;
    position: relative;
    line-height: 1;
    width: inherit;
    cursor: pointer;

    .header {
        display: inline-flex;
        align-items: center;
        width: 100%;
        height: 23px;

        .title {
            flex: 1 1 auto;
            display: inline-flex;
            align-items: center;
            margin-right: 5px;
            padding-left: 11px;
            color: $color-grey;
        }

        .svg-icon {
            width: 15px;
            height: 15px;

            svg {
                fill: $color-grey;
            }
        }
    }

    .list {
        position: absolute;
        top: 12px;
        left: 0;
        display: block;
        width: 100%;
        list-style: none;
        z-index: 10;
        padding-inline-start: 0;

        &.hidden {
            display: none;
        }

        > .list-item {
            text-align: left;
            color: $color-white;
            padding: 5px 0 5px 16px;
            line-height: 23px;
            background-color: rgba($color-grey-dark, 0.7);

            &:hover {
                background-color: rgba($color-grey-dark, 0.8);
            }
        }
    }
}