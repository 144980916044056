.react-confirm-alert-overlay {
    background: rgba(255, 255, 255, 0.9) !important;
    z-index: 1400;
}

.confirm {
    &__inner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: $padding-big;
        background-color: $color-modal-bg;
        border-radius: 2px;
        max-width: 600px;
        box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.15);

        &.error {
            .bold {
                color: $color-light-red;
            }

            .svg-icon {
                svg {
                    fill: $color-light-red;
                }
            }

            .btn {
                background-color: $color-light-red;
            }
        }

        &__close {
            align-self: flex-end;

            .svg-icon {
                width: 15px;
                height: 15px;

                svg {
                    fill: $color-blue;
                }
            }
        }

        &__text {
            align-self: flex-start;
            font-family: $font-real-text-pro-semilight;
            padding-top: 10px;
            font-size: 14px;
            line-height: 14px;

            > h6 {
                font-size: 20px;
                color: $color-grey-7B7B7B;
                line-height: 28px;
            }

            > p {
                color: rgba(#464646, 0.37)
            }

            > .bold {
                font-family: $font-real-text-pro-bold;
                line-height: 20px;
                color: $color-blue;

                &.error {
                    color: $color-light-red;
                }
            }
        }

        &__buttons {
            display: flex;
            width: 100%;
            justify-content: flex-start;
            margin-top: 20px;

            button {
                &:first-of-type {
                    margin-right: 10px;
                }
            }
        }
    }

}
