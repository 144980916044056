.scheduler-chain-modal {
    display: inline-flex;
    flex-direction: column;
    width: 100%;

    &__row {
        display: inline-flex;
        flex-direction: column;
        margin: 10px 0;

        &__wrapper {
            display: inline-flex;
            align-items: center;
        }

        &--flex-d-row {
            flex-direction: row;
        }

        &--align-items-end {
            align-items: flex-end;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        &__col {
            width: 50%;

            &--disabled {
                opacity: .5;
            }
            
            &:not(:first-of-type) {
                margin-left: 20px;
            }

            &__header {
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }

            .form-input {
                width: 200px;
                margin-top: 15px;
                border-bottom: 1px solid $color-select-border;
            }

            .time-picker {
                font-size: 14px;
            }
        }

        .btn {
            width: 20%;
        }

        .unsaved-changes {
            font-size: 14px;
            min-height: 20px;
            color: $color-light-red;
            margin-top: 10px;
        }
    }

    &__chips-container {
        width: 100%;

        &__chip {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-grey;
            border-radius: 25px;
            padding: 5px 15px;
            margin: 10px 10px 10px 0;
            color: $color-grey;
            cursor: pointer;

            &--selected {
                border: 1px solid transparent;
                color: $color-white;
                background-color: $color-blue;
            }

            &--rounded {
                width: 32px;
                height: 32px;
                border-radius: 50%;
            }
        }
    }
}