.job-chain-card {
    width: 150px;
    min-width: 150px;
    height: 200px;
    background-color: $color-grey-light;

    &--running {
        background-color: $color-grey;
    }

    &__next-separator {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 10px;
        width: 10px;

        > .svg-icon {
            width: 15px;
            height: 15px;
            fill: $color-blue;
        }
    }

    &.new-job-card {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: $color-blue;

        > .svg-icon {
            width: 16px;
            height: 16px;
            fill: $color-blue;
            margin-bottom: 10px;
            cursor: pointer;
        }

        p {
            text-align: center;
        }
    }

    &__inner {
        width: 100%;
        height: 100%;
        position: relative;
        transition: transform 0.5s;
        transform-style: preserve-3d;
        background-color: $color-grey-light;

        &.is-back {
            transform: rotateY(180deg);
        }

        &--type-move {
            cursor: move;
        }

        &__face {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            position: absolute;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            padding: 10px;

            &--back {
                transform: rotateY(180deg);
            }

            &__header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;
                height: 20px;

                 &__icon-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 18px;
                    height: 18px;
                    
                    &--with-bg {
                        background-color: rgba($color-grey, .7);
                        border-radius: 2px;
                    }

                    > .info-icon {
                        width: 18px;
                        height: 18px;
                        fill: rgba($color-grey, .7);
                    }

                    > .icon-close {
                        width: 8px;
                        height: 8px;
                        fill: $color-white;
                    }
                }

                &.j-end {
                    justify-content: end;
                }
            }

            &__content {
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1;
                justify-content: flex-end;
                width: 100%;

                &--justify-center {
                    justify-content: center;
                }

                &__type {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 50px;
                    background-color: $color-grey;
                    font-family: $font-real-text-pro-bold;
                    border-radius: 50%;
                    opacity: .5;
                    color: rgba($color-white, .7);

                    span {
                        max-width: 45px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                &__name {
                    width: 100%;
                    font-family: $font-real-text-pro-bold;
                    margin: 10px 0px 0px 0px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    text-align: center;
                }

                &__tenant {
                    margin: 0px 0px 10px 0px;
                }

                &__back-wrapper {
                    font-size: 12px;
                    width: 100%;
                    
                    &:not(:first-child) {
                        margin-top: 10px;
                    }

                    > .section-title {
                        font-size: 12px;
                    }
                }

                &__select-wrapper {
                    width: 100%;
                }

                &__checkbox {
                    display: inline-flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin: 10px 0;

                    > span {
                        text-align: center;
                        margin-bottom: 5px;
                    }
                }
            }

            &__bottom-btns {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                white-space: nowrap;

                .btn {
                    font-size: 12px;
                    width: 80%;
                    padding: 0.6em 0.4rem;

                    > .svg-icon {
                        width: 16px;
                        height: 16px;
                    }

                    span {
                        &:nth-of-type(2) {
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }

                .remove-job-icon {
                    width: 16px;
                    height: 16px;
                    fill: $error-bg-color;
                    margin-left: 10px;
                    cursor: pointer;
                }
            }
        }
    }
}